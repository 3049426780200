import { useMemo, useReducer } from "react";
export function useManualReload(fn, dependencies = []) {
    const [gen, reload] = useReducer((x)=>x + 1, 0);
    const value = useMemo(fn, [
        gen,
        ...dependencies
    ]);
    return [
        value,
        reload
    ];
}
