export function getFaceDir(start, dest) {
    const diff = {
        x: dest.x - start.x,
        y: dest.y - start.y
    };
    const deg = Math.atan2(diff.y, diff.x) / Math.PI * 180;
    if (deg >= 45 && deg <= 135) {
        return "front";
    } else if (deg <= -135 || deg >= 135) {
        return "left";
    } else if (deg >= -45 && deg <= 45) {
        return "right";
    } else {
        return "back";
    }
}
export function getNextPosition(x, y, face) {
    switch(face){
        case "front":
            return [
                x,
                y + 1
            ];
        case "left":
            return [
                x - 1,
                y
            ];
        case "right":
            return [
                x + 1,
                y
            ];
        case "back":
            return [
                x,
                y - 1
            ];
    }
}
export function getPrevPosition(x, y, face) {
    switch(face){
        case "front":
            return [
                x,
                y - 1
            ];
        case "left":
            return [
                x + 1,
                y
            ];
        case "right":
            return [
                x - 1,
                y
            ];
        case "back":
            return [
                x,
                y + 1
            ];
    }
}
export function getRelativeNeighborPoints(x, y, face) {
    switch(face){
        case "front":
            return [
                [
                    x,
                    y + 1,
                    "front"
                ],
                [
                    x + 1,
                    y,
                    "right"
                ],
                [
                    x - 1,
                    y,
                    "left"
                ],
                [
                    x,
                    y - 1,
                    "back"
                ]
            ];
        case "left":
            return [
                [
                    x - 1,
                    y,
                    "left"
                ],
                [
                    x,
                    y + 1,
                    "front"
                ],
                [
                    x,
                    y - 1,
                    "back"
                ],
                [
                    x + 1,
                    y,
                    "right"
                ]
            ];
        case "right":
            return [
                [
                    x + 1,
                    y,
                    "right"
                ],
                [
                    x,
                    y - 1,
                    "back"
                ],
                [
                    x,
                    y + 1,
                    "front"
                ],
                [
                    x - 1,
                    y,
                    "left"
                ]
            ];
        case "back":
            return [
                [
                    x,
                    y - 1,
                    "back"
                ],
                [
                    x - 1,
                    y,
                    "left"
                ],
                [
                    x + 1,
                    y,
                    "right"
                ],
                [
                    x,
                    y + 1,
                    "front"
                ]
            ];
    }
}
