import { useEffect, useRef, useState } from "react";
import ArrayMorph from "array-morph";
export const useArrayMorph = (value, delay)=>{
    const [morph] = useState(()=>new ArrayMorph([]));
    const handler = useRef(0);
    const [result, setResult] = useState([]);
    useEffect(()=>{
        morph.update(value != null ? value : []);
        if (handler.current) clearInterval(handler.current);
        handler.current = setInterval(()=>{
            const { value, done } = morph.next();
            setResult(value);
            if (done) {
                clearInterval(handler.current);
                handler.current = 0;
            }
        }, delay);
        return ()=>{
            if (handler.current) clearInterval(handler.current);
            handler.current = 0;
        };
    }, [
        value
    ]);
    return result;
};
