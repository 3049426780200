import { RenderTarget } from "./RenderTarget";
export class EntityTarget extends RenderTarget {
    get zIndex() {
        return this.entity.y;
    }
    render(ctx) {
        var _this_entity_w;
        const w = (_this_entity_w = this.entity.w) != null ? _this_entity_w : this.defs.w;
        var _this_entity_h;
        const h = (_this_entity_h = this.entity.h) != null ? _this_entity_h : this.defs.h;
        ctx.drawImage(this.spritesheet, this.defs.x, this.defs.y, this.defs.w, this.defs.h, this.entity.x - w * this.defs.pivotX, this.entity.y - h * this.defs.pivotY, w, h);
    }
    constructor(spritesheet, entity, defs){
        super(), this.spritesheet = spritesheet, this.entity = entity, this.defs = defs;
    }
}
