import useSWR from "swr";
import useSWRMutation from "swr/mutation";
export function useActionQuery(action, ...args) {
    return useSWR({
        name: action.name,
        args
    }, ()=>action(...args));
}
export function useActionWithOptions(action, config, ...args) {
    return useSWR({
        name: action.name,
        args
    }, ()=>action(...args), config);
}
export function useSuspenseActionQuery(action, ...args) {
    return useSWR({
        name: action.name,
        args
    }, ()=>action(...args), {
        suspense: true
    });
}
export function useActionMutation(action, ...params) {
    return useSWRMutation({
        name: action.name
    }, (key, { arg })=>action(...params, ...arg));
}
