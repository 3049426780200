import { transactionalRead, transactionalReadWrite } from "tuple-database";
(function(TempPlayers) {
    TempPlayers.reset = transactionalReadWrite()((tx, list)=>{
        tx.scan({
            prefix: []
        }).forEach(({ key })=>tx.remove(key));
        list.forEach((id)=>tx.set([
                id
            ], true));
    });
    TempPlayers.get = transactionalRead()((tx)=>tx.scan({
            prefix: []
        }).map(({ key })=>key[0]));
    TempPlayers.joinWithLimit = transactionalReadWrite()((tx, id, limit)=>{
        tx.set([
            id
        ], true);
        if (tx.scan({
            prefix: []
        }).length > limit) {
            throw new Error("达到小屋容量上限，请手动移除旧的角色再加入。");
        }
    });
    TempPlayers.count = transactionalRead()((tx)=>tx.scan({
            prefix: []
        }).length);
    TempPlayers.has = transactionalRead()((tx, id)=>tx.exists([
            id
        ]));
    TempPlayers.toggle = transactionalReadWrite()((tx, id)=>tx.exists([
            id
        ]) ? tx.remove([
            id
        ]) : tx.set([
            id
        ], true));
})(TempPlayers || (TempPlayers = {}));
export var TempPlayers;
