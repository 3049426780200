export function arrayToggle(arr, val, forced) {
    if (forced && arr.includes(val)) {
        return [
            ...arr
        ];
    } else if (forced === false || arr.includes(val)) {
        return arr.filter((v)=>v !== val);
    }
    return [
        ...arr,
        val
    ];
}
