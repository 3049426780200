import { transactionalRead, transactionalWrite } from "tuple-database";
(function(TabBarIndex) {
    TabBarIndex.selected = transactionalRead()((tx)=>{
        var _tx_get;
        return (_tx_get = tx.get([
            "selected"
        ])) != null ? _tx_get : 0;
    });
    TabBarIndex.selectIndex = transactionalWrite()((tx, idx)=>{
        tx.set([
            "selected"
        ], idx);
    });
})(TabBarIndex || (TabBarIndex = {}));
(function(ModalIndex) {
    ModalIndex.mode = transactionalRead()((tx)=>{
        var _tx_get;
        return (_tx_get = tx.get([
            "mode"
        ])) != null ? _tx_get : "default";
    });
    ModalIndex.setMode = transactionalWrite()((tx, mode)=>{
        tx.set([
            "mode"
        ], mode);
    });
})(ModalIndex || (ModalIndex = {}));
export var TabBarIndex, ModalIndex;
