import { transactionalRead, transactionalReadWrite, transactionalWrite } from "tuple-database";
(function(BatteryState) {
    BatteryState.enabled = transactionalRead()((tx)=>tx.exists([
            "enabled"
        ]));
    BatteryState.energy = transactionalRead()((tx)=>{
        var _tx_get;
        return (_tx_get = tx.get([
            "energy"
        ])) != null ? _tx_get : "00:00:00";
    });
    BatteryState.hasEnergy = transactionalRead()((tx)=>tx.get([
            "energy"
        ]) != "00:00:00" || tx.get([
            "premiumEnergy"
        ]) != "00:00:00");
    BatteryState.premiumEnergy = transactionalRead()((tx)=>{
        var _tx_get;
        return (_tx_get = tx.get([
            "premiumEnergy"
        ])) != null ? _tx_get : "00:00:00";
    });
    BatteryState.updateEnergy = transactionalWrite()((tx, energy, premiumEnergy)=>{
        tx.set([
            "energy"
        ], energy);
        tx.set([
            "premiumEnergy"
        ], premiumEnergy);
    });
    BatteryState.toggleEnabled = transactionalWrite()((tx, enabled)=>{
        if (enabled) {
            tx.set([
                "enabled"
            ], true);
        } else {
            tx.remove([
                "enabled"
            ]);
        }
    });
})(BatteryState || (BatteryState = {}));
(function(CabinState) {
    CabinState.updatePlayerList = transactionalReadWrite()((tx, list)=>{
        for (const { key } of tx.scan({
            prefix: [
                "player"
            ]
        })){
            tx.remove(key);
        }
        for (const { key } of tx.scan({
            prefix: [
                "player-enabled"
            ]
        })){
            tx.remove(key);
        }
        for (const { id, owner, enabled } of list){
            tx.set([
                "player",
                id
            ], owner);
            if (enabled) {
                tx.set([
                    "player-enabled",
                    id
                ], true);
            }
        }
    });
    CabinState.hasEnabledPlayers = transactionalRead()((tx)=>tx.scan({
            prefix: [
                "player-enabled"
            ]
        }).length > 0);
    CabinState.enabledPlayers = transactionalRead()((tx)=>tx.scan({
            prefix: [
                "player-enabled"
            ]
        }).map((x)=>x.key[1]));
    CabinState.enabledPlayerCount = transactionalRead()((tx)=>tx.scan({
            prefix: [
                "player-enabled"
            ]
        }).length);
    CabinState.hasSelectedPlayer = transactionalRead()((tx)=>tx.exists([
            "selected-player"
        ]));
    CabinState.selectedPlayer = transactionalRead()((tx)=>tx.get([
            "selected-player"
        ]));
    CabinState.selectPlayer = transactionalWrite()((tx, id)=>{
        if (id) tx.set([
            "selected-player"
        ], id);
        else tx.remove([
            "selected-player"
        ]);
    });
    CabinState.playerJoined = transactionalRead()((tx, id)=>tx.exists([
            "player",
            id
        ]));
    CabinState.checkPlayerEnabled = transactionalRead()((tx, id)=>tx.exists([
            "player-enabled",
            id
        ]));
    CabinState.hostPlayers = transactionalRead()((tx, myid)=>tx.scan({
            prefix: [
                "player"
            ]
        }).filter((x)=>x.value === myid).map((x)=>x.key[1]));
    CabinState.guestPlayers = transactionalRead()((tx, myid)=>{
        const ids = tx.scan({
            prefix: [
                "player"
            ]
        }).filter((x)=>x.value != myid).map((x)=>({
                id: x.key[1],
                owner: x.value
            }));
        const enabled = tx.scan({
            prefix: [
                "player-enabled"
            ]
        }).map((x)=>x.key[1]);
        return ids.map(({ id, owner })=>({
                id,
                enabled: enabled.includes(id),
                owner
            }));
    });
})(CabinState || (CabinState = {}));
export var BatteryState, CabinState;
