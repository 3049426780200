export function manhattan(a, b) {
    return Math.abs(a.x - b.x) + Math.abs(a.y - b.y);
}
let TracePoint = class TracePoint {
    get index() {
        return `${this.x}-${this.y}`;
    }
    get f() {
        return this.g + this.h + this.weight;
    }
    createPath(path = []) {
        path.unshift({
            x: this.x,
            y: this.y
        });
        if (this.parentNode) {
            this.parentNode.createPath(path);
        }
        return path;
    }
    constructor(x, y, weight, h, g, parentNode = undefined){
        this.x = x;
        this.y = y;
        this.weight = weight;
        this.h = h;
        this.g = g;
        this.parentNode = parentNode;
        this.visited = false;
    }
};
TracePoint.Builder = class TracePointBuilder {
    build(point, parent) {
        const weight = this.getWeight(point.x, point.y);
        if (weight == null || weight >= 1) return undefined;
        const h = manhattan(point, this.dest);
        const newPoint = new TracePoint(point.x, point.y, weight, h, parent == null ? 0 : parent.g + h, parent);
        const oldPoint = this.cache.get(newPoint.index);
        if (oldPoint && (oldPoint.f < newPoint.f || oldPoint.f === newPoint.f && oldPoint.h < newPoint.h)) return oldPoint;
        this.cache.set(newPoint.index, newPoint);
        return newPoint;
    }
    getSmallest() {
        return [
            ...this.cache.values()
        ].reduce((current, node)=>{
            if (node.visited === true) return current;
            if (current == null) return node;
            if (current.f < node.f) return current;
            if (current.f > node.f) return node;
            return current.h < node.h ? current : node;
        }, undefined);
    }
    getCachedPointAt(x, y) {
        return this.cache.get(`${x}-${y}`);
    }
    constructor(getWeight, dest){
        this.getWeight = getWeight;
        this.dest = dest;
        this.cache = new Map();
    }
};
export function findPath(gridInput, start, dest) {
    const builder = new TracePoint.Builder(gridInput, dest);
    let current = builder.build(start);
    while(current != null && (current.x !== dest.x || current.y !== dest.y)){
        current.visited = true;
        const neighbours = [
            {
                x: current.x - 1,
                y: current.y
            },
            {
                x: current.x + 1,
                y: current.y
            },
            {
                x: current.x,
                y: current.y - 1
            },
            {
                x: current.x,
                y: current.y + 1
            }
        ];
        neighbours.forEach((coords)=>builder.build(coords, current));
        current = builder.getSmallest();
    }
    const destPoint = builder.getCachedPointAt(dest.x, dest.y);
    return destPoint == null ? void 0 : destPoint.createPath();
}
