import { useCallback, useEffect, useRef } from "react";
import { toast } from "react-toastify";
export function useToastDismiss() {
    const ref = useRef([]);
    useEffect(()=>()=>{
            ref.current.map((key)=>toast.dismiss(key));
        }, []);
    return useCallback((id)=>{
        ref.current.push(id);
    }, []);
}
