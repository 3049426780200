export function getRandomItem(arr, getWeight) {
    return arr[getRandomIndex(arr, getWeight)];
}
export function getRandomIndex(arr, getWeight) {
    const weights = arr.map(getWeight != null ? getWeight : ()=>1);
    const sum = weights.reduce((a, b)=>a + b, 0);
    const alignedWeights = weights.map((w)=>w / sum);
    const probabilities = alignedWeights.reduce((accumulator, value)=>[
            ...accumulator,
            accumulator[accumulator.length - 1] + value
        ], [
        0
    ]);
    probabilities.shift();
    const random = Math.random();
    return probabilities.findIndex((p)=>p > random);
}
export function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}
