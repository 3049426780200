import { useLayoutEffect, useRef } from "react";
const cache = new Map();
export function useMeasuredSize(prefix) {
    const ref = useRef(null);
    useLayoutEffect(()=>{
        let observer = cache.get(prefix);
        if (!observer) {
            observer = new ResizeObserver((entries)=>{
                for (const { target, contentRect } of entries){
                    if (target instanceof HTMLElement) {
                        target.style.setProperty(`--${prefix}-width`, `${contentRect.width}px`);
                        target.style.setProperty(`--${prefix}-height`, `${contentRect.height}px`);
                    }
                }
            });
            cache.set(prefix, observer);
        }
        const target = ref.current;
        if (!target) return;
        observer.observe(target);
        return ()=>observer.unobserve(target);
    }, [
        prefix
    ]);
    return ref;
}
