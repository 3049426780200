export function shadow(base, extra) {
    return Object.create(base, Object.getOwnPropertyDescriptors(extra));
}
export function shadowRef(baseRef, extra) {
    return new Proxy(extra, {
        get (target, prop) {
            var _baseRef_current;
            if (prop in target) {
                // @ts-ignore
                return target[prop];
            }
            // @ts-ignore
            return (_baseRef_current = baseRef.current) == null ? void 0 : _baseRef_current[prop];
        }
    });
}
