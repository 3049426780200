import { useCallback, useState, useTransition } from "react";
export function useTransitionStateHook() {
    const [isPending, startTransition] = useTransition();
    return [
        isPending,
        useCallback((defaultValue)=>{
            const [value, setValue] = useState(defaultValue);
            const setValueAsTransition = useCallback((action)=>{
                startTransition(()=>setValue(action));
            }, []);
            return [
                value,
                setValueAsTransition
            ];
        }, [
            startTransition
        ])
    ];
}
