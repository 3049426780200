import { useState } from "react";
import { withAbort } from "./abort";
import { createEventSource } from "./eventsource";
import { useVisibleEffect } from "./useVisibilityChange";
export function useCabinEvents(id) {
    const [events, setEvents] = useState([]);
    useVisibleEffect(()=>withAbort((signal)=>{
            const source = createEventSource(`/events/cabin/${id}`, signal);
            source.addEventListener("message", ({ data })=>setEvents(JSON.parse(data)), {
                signal
            });
        }));
    return events;
}
