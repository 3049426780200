import { useCallback, useInsertionEffect, useRef, useState } from "react";
export function useButtonAction(callback) {
    const latestRef = useRef(shouldNotBeInvokedBeforeMount);
    const lockRef = useRef(false);
    const [loading, setLoading] = useState(false);
    useInsertionEffect(()=>{
        latestRef.current = callback;
    }, [
        callback
    ]);
    const onClick = useCallback((e)=>{
        e.stopPropagation();
        if (lockRef.current) return;
        const fn = latestRef.current;
        const ret = fn(e);
        lockRef.current = true;
        setLoading(true);
        ret.finally(()=>setLoading(lockRef.current = false));
        return;
    }, []);
    return [
        onClick,
        loading
    ];
}
function shouldNotBeInvokedBeforeMount() {
    throw new Error("foxact: the stablized handler cannot be invoked before the component has mounted.");
}
